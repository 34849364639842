<main class="vh-100 w-100 bg-white flex flex-wrap-ns flex-column">
  <section class="w-20-ns h-100-ns w-40-m h-100-m h-20 w-100 bg-dark-gray flex flex-column-ns flex wrap justify-center items-center">
    <div *ngIf="!onwardSelected();else onwardData"></div>
    <ng-template #onwardData>
      <div class="h5 w5 bg-black ma1 flex justify-center items-center inline-flex">
        <table class="w-100">
          <tr>
            <td colspan="2" class="f3-ns f5 fjalla white pa2-ns pa1-m pcustom">{{round.sourceCode}} - {{round.destCode}} ({{dformatter(queryData.dot)}})</td>
          </tr>
          <tr>
            <td colspan="2" class="f4-ns f6 fjalla white pa2-ns pa1-m pcustom">{{round.airline}} {{round.flightNumber}}</td>
          </tr>
          <tr>
            <td class="f4-ns f6 fjalla white pa2-ns pa1-m pcustom">Departure</td>
            <td class="f4-ns f6 fjalla white pa2-ns pa1-m">{{round.deptTime}}</td>
          </tr>
          <tr>
            <td class="f4-ns f6 fjalla white pa2-ns pa1-m pcustom">Arrival</td>
            <td class="f4-ns f6 fjalla white pa2-ns pa1-m pcustom">{{round.arrivalTime}}</td>
          </tr>
          <tr>
            <td class="f4-ns f6 fjalla white pa2-ns pa1-m pcustom">Fare {{round.fare}}</td>
            <td><a (click)="removeOnward()"><mat-icon>delete</mat-icon></a></td>
          </tr>
        </table>
      </div>
    </ng-template>
    <div *ngIf="!returnSelected();else returndata"></div>
    <ng-template #returndata>
      <div class="h5 w5 bg-black ma1 flex justify-center items-center inline-flex">
        <table class="w-100">
          <tr>
            <td colspan="2" class="f3-ns f5 fjalla white pa2-ns pa1-m pcustom">{{returnflight.sourceCode}} - {{returnflight.destCode}} ({{dformatter(queryData.dor)}})</td>
          </tr>
          <tr>
            <td colspan="2" class="f4-ns f6 fjalla white pa2-ns pa1-m pcustom">{{returnflight.airline}} {{returnflight.flightNumber}}</td>
          </tr>
          <tr>
            <td class="f4-ns f6 fjalla white pa2-ns pa1-m pcustom">Departure</td>
            <td class="f4-ns f6 fjalla white pa2-ns pa1-m">{{returnflight.deptTime}}</td>
          </tr>
          <tr>
            <td class="f4-ns f6 fjalla white pa2-ns pa1-m pcustom">Arrival</td>
            <td class="f4-ns f6 fjalla white pa2-ns pa1-m pcustom">{{returnflight.arrivalTime}}</td>
          </tr>
          <tr>
            <td class="f4-ns f6 fjalla white pa2-ns pa1-m pcustom">Fare {{returnflight.fare}}</td>
            <td><a (click)="removeReturn()"><mat-icon>delete</mat-icon></a></td>
          </tr>
        </table>
      </div>
    </ng-template>
    <div *ngIf="!nullCheck(); else subbtn"></div>
    <ng-template #subbtn>
      <div><button [disabled]="!nullCheck()" (click)="onSubmit()" class="w5-ns h2-ms h3-m w3-m h3 w3 pa2 ma1" mat-raised-button color="primary">Done</button></div>
    </ng-template>
  </section>
  <section class="w-80-ns h-100-ns w-60-m h-100-m h-80 w-100 bg-black">
    <mat-tab-group mat-stretch-tabs headerPosition="below" class="overflow-y-scroll vh-100-ns h-100">
      <mat-tab label="Onward"><app-roundonward></app-roundonward></mat-tab>
      <mat-tab label="Return"> <app-roundreturn></app-roundreturn> </mat-tab>
    </mat-tab-group>
  </section>
</main>
