<h1 mat-dialog-title>Edit Details</h1>
<div mat-dialog-content>
  <form #editticketform = "ngForm">
    <mat-form-field>
      <mat-placeholder>Name</mat-placeholder>
      <input matInput type="text" name="name" [(ngModel)]="data.name">
    </mat-form-field>
    <mat-form-field>
      <mat-placeholder>Age</mat-placeholder>
      <input matInput type="text" name="age" [(ngModel)]="data.age">
    </mat-form-field>
    <mat-form-field>
      <mat-placeholder>Gender</mat-placeholder>
      <mat-select name="gender" [(ngModel)] = data.gender>
        <mat-option value="Male">Male</mat-option>
        <mat-option value="Female">Female</mat-option>
        <mat-option value="Others">Others</mat-option>
      </mat-select>
    </mat-form-field>
  </form>
</div>
<div mat-dialog-actions>
  <button mat-button (click)="onNoClick()">Cancel</button>
  <button mat-button [mat-dialog-close]="editticketform.value" cdkFocusInitial>Submit</button>
</div>
