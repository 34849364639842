<article *ngIf="!adminstatus(); else adminpostconsole">
  <h2 class="f-6-ns f1 fw4 pa4 fjalla mv0 flex justify-center">Travel Ideas</h2>
  <div class="flex justify-center flex-wrap pa3" *ngIf="loginstatus()">
    <button mat-raised-button color="primary" routerLink="/blog-create" class="pa3 w5-ns w-100-m w-100 ma1">Create a Blog Post</button>
  </div>
  <div class="cf pa2 flex flex-wrap justify-center">
    <div class="ma3" *ngFor="let post of posts">
      <a routerLink="/blog-post/{{post.blogid}}" class="db link dim tc">
        <img src="{{post.imgurl}}" alt="{{post.articletitle}}r" style="object-fit: cover;" class="customhw db outline black-10"/>
        <div class="flex justify-center flex-column cw24 pt2">
          <div class="f4 fjalla ml0 white cw24">{{post.articletitle}}</div>
          <div class="ml0 gray cw24">By - {{post.author}}</div>
        </div>
      </a>
    </div>
  </div>
</article>
<ng-template #adminpostconsole>
  <article class="pa3">
    <h2 class="f-6-ns f1 fw4 pa4 fjalla mv0 flex justify-center">Manage Travel Ideas</h2>
    <div class="flex justify-center flex-wrap pa3">
      <button mat-raised-button color="primary" routerLink="/blog-create" class="pa3 w5-ns w-100-m w-100 ma1">Create a Blog Post</button>
    </div>
    <mat-accordion *ngFor="let post of posts" class="pa3">
      <mat-expansion-panel class="pa2">
        <mat-expansion-panel-header>
          <mat-panel-title>
            {{post.articletitle}}
          </mat-panel-title>
          <mat-panel-description class="flex justify-end f5-ns f5-m f6 tr">
            {{post.author}}
          </mat-panel-description>
        </mat-expansion-panel-header>
        <img src="{{post.imgurl}}" alt="{{post.articletitle}}r" style="object-fit: cover;height: 400px" class="db outline black-10 h5 w-100"/>
        <p>
          {{post.articlecontents}}
        </p>
        <div class="flex justify-center flex-wrap pa3" *ngIf="loginstatus()">
        <button mat-raised-button color="primary" routerLink="/blog-edit/{{post.blogid}}" class="pa3 w5-ns w-100-m w-100 ma1">Manage Post</button>
      </div>
      </mat-expansion-panel>
    </mat-accordion>
  </article>
</ng-template>

