<div *ngIf="!flag" class="w-100 vh-100 flex justify-center items-center"><mat-spinner></mat-spinner></div>
<div style="padding-top: 50px"></div>
<section class="flex flex-wrap justify-center">
  <div class="pt1 pb2 flex flex-wrap justify-center items-center">
    <span class="pa2 f4-ns f6">Sort By</span>
    <mat-button-toggle-group class="f4-ns f6 ma1" name="ticketType" [(ngModel)] = "orderby">
      <mat-button-toggle class="" value="Fare" (click)="refresh()">Fare</mat-button-toggle>
      <mat-button-toggle class="" value="DeptTime" (click)="refresh()">Departure Time</mat-button-toggle>
      <mat-button-toggle class="" value="ArrivalTime" (click)="refresh()">Arrival Time</mat-button-toggle>
      <mat-button-toggle class="" value="Duration" (click)="refresh()">Duration</mat-button-toggle>
    </mat-button-toggle-group>
    <span class="pa2 f4-ns f6">Sort Type</span>
    <mat-button-toggle-group class="f4-ns f6 ma1" name="ticketType" [(ngModel)] = "sort">
      <mat-button-toggle class="" value="asc" (click)="refresh()"><mat-icon>arrow_upward</mat-icon></mat-button-toggle>
      <mat-button-toggle class="" value="dsc" (click)="refresh()"><mat-icon>arrow_downward</mat-icon></mat-button-toggle>
    </mat-button-toggle-group>
  </div>
</section>
<section class="flex-wrap pa2" style="background-color: #212121">
  <div *ngFor="let flight of queryResult">
    <div class="pa3 w-auto ma3 mr6-ns ml6-ns mr2-m ml2-m flex flex-column hover-bg-black bg-dark-gray" (click)="pick(flight)">
      <div class="flex flex-wrap items-center pa3">
        <div class="w-25 tc-ns tl pa3-ns pa1 f3-ns f5 fjalla"><span>{{flight.airline+" "}}</span><br><span>{{flight.flightNumber}}</span></div>
        <div class="w-50 tc flex justify-center">
          <div class="pa3-ns pa1 f3-ns f5 fjalla flex items-center justify-center">
            <span>{{flight.deptTime}}<br>{{ flight.sourceCode}}</span>
          </div>
          <div class="pa3-ns pa1 f4-ns f6 fjalla">
            <span>{{flight.duration}}</span><br><mat-icon>arrow_forward</mat-icon>
          </div>
          <div class="pa3-ns pa1 f3-ns f5 fjalla flex items-center justify-center">
            <span>{{flight.arrivalTime}} <br>{{flight.destCode}}</span>
          </div>
        </div>
        <div class="w-25 tc-ns tr pa3-ns pa1 f2-ns f5 fjalla"><span>₹{{flight.fare}}</span></div>
      </div>
    </div>
  </div>
</section>

