<section class="bgimg">
  <div class="bg-black-80">
    <div class="flex flex-column pa4">
      <div class="w-100 pa4">
        <div class="pt4 pb4">
          <span class="f1 fjalla">Book your flight now!</span>
        </div>
        <form #tripTypeForm="ngForm">
          <mat-button-toggle-group class="w-100" name="ticketType" aria-label="Font Style" [(ngModel)] = "tripType">
            <mat-button-toggle class="w-50" value="oneway">One Way</mat-button-toggle>
            <mat-button-toggle class="w-50" value="round-trip">Round Trip</mat-button-toggle>
          </mat-button-toggle-group>
        </form>
      </div>
      <div *ngIf="tripType==='oneway' then oneway; else round" ></div>
      <ng-template #oneway>
        <div class="w-100 flex flex-column">
          <form #oneWayForm = "ngForm" (ngSubmit)="oneWaySubmit(oneWayForm.value)">
          <div class="flex flex-wrap justify-center">


            <mat-form-field appearance="outline" class="w5-ns w-100 ma2-ns">
              <mat-label>Departure</mat-label>
              <input matInput
                     aria-label="Departure"
                     [matAutocomplete]="autodept"
                     [formControl]="deptCtrl">
              <mat-autocomplete #autodept="matAutocomplete">
                <mat-option *ngFor="let airport of filteredDept | async" [value]="airport.airportCode">
                  <span>{{airport.city}} - {{airport.airportCode}}</span> |
                  <small>{{airport.country}}</small>
                </mat-option>
              </mat-autocomplete>
            </mat-form-field>

            <mat-form-field appearance="outline" class="w5-ns w-100 ma2-ns">
              <mat-label>Arrival</mat-label>
              <input matInput
                     aria-label="Arrival"
                     [matAutocomplete]="autoarrival"
                     [formControl]="arrivalCtrl">
              <mat-autocomplete #autoarrival="matAutocomplete">
                <mat-option *ngFor="let airport of filteredArrival | async" [value]="airport.airportCode">
                  <span>{{airport.city}} - {{airport.airportCode}}</span> |
                  <small>{{airport.country}}</small>
                </mat-option>
              </mat-autocomplete>
            </mat-form-field>

              <mat-form-field appearance="outline" class="w5-ns w-100 ma2-ns">
                <mat-label>Choose a date</mat-label>
                <input matInput name="dot" [min]="minDate" required [matDatepicker]="picker" [value]="curDate" [(ngModel)]="curDate">
                <mat-datepicker-toggle matSuffix [for]="picker">
                  <mat-icon matDatepickerToggleIcon>keyboard_arrow_down</mat-icon>
                </mat-datepicker-toggle>
                <mat-datepicker #picker></mat-datepicker>
              </mat-form-field>

              <mat-form-field appearance="outline" class="w5-ns w-100 ma2-ns">
                <mat-label>Travellers</mat-label>
                <select name="count" matNativeControl required [(ngModel)]="minpass">
                  <option *ngFor="let n of nums">{{n}}</option>
                </select>
              </mat-form-field>
          </div>
          <div class="flex justify-center w-100">
            <button mat-raised-button class="w-100 w-40-ns pa3 cm">Fly</button>
          </div>
          </form>
        </div>

      </ng-template>

      <ng-template #round>
        <div class="w-100 flex flex-column">
          <form #twoWayForm = "ngForm" (ngSubmit)="roundTripSubmit(twoWayForm.value)">
          <div class="flex flex-wrap justify-center">


              <mat-form-field appearance="outline" class="w5-ns w-100 ma2-ns">
                <mat-label>Departure</mat-label>
                <input matInput
                       aria-label="Departure"
                       [matAutocomplete]="autodept"
                       [formControl]="deptCtrl">
                <mat-autocomplete #autodept="matAutocomplete">
                  <mat-option *ngFor="let airport of filteredDept | async" [value]="airport.airportCode">
                    <span>{{airport.city}} - {{airport.airportCode}}</span> |
                    <small>{{airport.country}}</small>
                  </mat-option>
                </mat-autocomplete>
              </mat-form-field>

              <mat-form-field appearance="outline" class="w5-ns w-100 ma2-ns">
                <mat-label>Arrival</mat-label>
                <input matInput
                       aria-label="Arrival"
                       [matAutocomplete]="autoarrival"
                       [formControl]="arrivalCtrl">
                <mat-autocomplete #autoarrival="matAutocomplete">
                  <mat-option *ngFor="let airport of filteredArrival | async" [value]="airport.airportCode">
                    <span>{{airport.city}} - {{airport.airportCode}}</span> |
                    <small>{{airport.country}}</small>
                  </mat-option>
                </mat-autocomplete>
              </mat-form-field>
              <!-- From date -->
              <mat-form-field appearance="outline" class="w5-ns w-100 ma2-ns">
                <mat-label>Departure</mat-label>
                <input matInput [min]="minDate" [matDatepicker]="departurePicker" [(ngModel)]="curDate" name="dot">
                <mat-datepicker-toggle matSuffix [for]="departurePicker">
                  <mat-icon matDatepickerToggleIcon>keyboard_arrow_down</mat-icon>
                </mat-datepicker-toggle>
                <mat-datepicker #departurePicker></mat-datepicker>
              </mat-form-field>
              <!-- To date -->
              <mat-form-field appearance="outline" class="w5-ns w-100 ma2-ns">
                <mat-label>Return</mat-label>
                <input matInput [min]="curDate" [matDatepicker]="returnPicker" ngModel name="dor">
                <mat-datepicker-toggle matSuffix [for]="returnPicker">
                  <mat-icon matDatepickerToggleIcon>keyboard_arrow_down</mat-icon>
                </mat-datepicker-toggle>
                <mat-datepicker #returnPicker></mat-datepicker>
              </mat-form-field>

              <mat-form-field appearance="outline" class="w4-ns w-100 ma2-ns">
                <mat-label>Travellers</mat-label>
                <select name="count" matNativeControl required [(ngModel)]="minpass">
                  <option *ngFor="let n of nums">{{n}}</option>
                </select>
              </mat-form-field>

          </div>
          <div class="flex justify-center w-100">
            <button mat-raised-button class="w-100 w-40-ns pa3 cm">Fly</button>
          </div>
          </form>
        </div>
      </ng-template>
    </div>
  </div>
</section>
