<div class="pa4 f1 flex justify-center fjalla">Places to check out!</div>
<div class="cf pa2 flex flex-wrap justify-center">
  <div class="ma3" *ngFor="let post of posts">
    <a routerLink="/blog-post/{{post.blogid}}" class="db link dim tc">
      <img src="{{post.imgurl}}" alt="{{post.articletitle}}" style="object-fit: cover;" class="db outline black-10 customhw"/>
      <div class="flex justify-center">
        <div class="pt2 f4 white w5 fjalla">{{post.articletitle}}</div>
      </div>
    </a>
  </div>
</div>
